import React from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'
import Layout from '../layouts'

const tagsWrapper = css`
  margin-top: 200px;
  
  @media (max-width:1000px) {
    margin-top: 100px;
  }
`

const tagShape = css`
  a& {
    position: relative;
    display: inline-block;
    margin: 0 1em .6em .6em;
    padding: .6em .6em .6em 1.2em;
    line-height: 1;
    color: #fff;
    text-decoration: none;
    background-color: #0000ee;
  }
  
  &:before {
    position: absolute;
    top: 0;
    left: -1em;
    content: '';
    border-width: 1.1em 1em 1.1em 0;
    border-style: solid;
    border-color: transparent #0000ee transparent transparent;
  }
  
  &:after {
    position: absolute;
    top: center;
    left: 0;
    content: '●';
    color: #fff;
  }
`

const Tags = ({pageContext}) => {
  const { tags } = pageContext
  return (
    <Layout>
      <div className="wrapper">
        <div className={tagsWrapper}>
          {tags.map((tagName, index) => {
            return (
                <Link to={`/tags/${tagName}`} className={tagShape}>
                  {tagName}
                </Link>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Tags